/*Link to our internal fonts*/
@font-face {
  font-family: "VisitDenmark";
  src: url('./font/VisitDenmark-Bold.otf');
  src: url('./font/VisitDenmark-ExtraBold.otf');
  src: url('./font/VisitDenmark-Medium.otf');
  src: url('./font/VisitDenmark-Outline-Medium.otf');
  src: url('./font/VisitDenmark-Outline-Regular.otf');
  src: url('./font/VisitDenmark-Regular.otf');
  src: url('./font/VisitDenmark-SemiBold.otf');
}

body {
  font-family: VisitDenmark;

}

nav {
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-decoration: none;
  font-size: 8px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  background: linear-gradient(360deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.382) 100%);

}

.all-nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.navlink {
  padding: 12px;
  text-decoration: none;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.nav-icon:active {
  filter: invert(13%) sepia(100%) saturate(4691%) hue-rotate(332deg) brightness(60%) contrast(106%);
}

.active {
  filter: invert(13%) sepia(100%) saturate(4691%) hue-rotate(332deg) brightness(60%) contrast(106%);
}


.points-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex-direction: row;
  flex-wrap: nowrap;

}

.header-container {
  display: flex;
  justify-content: space-between;
}

.discount-container {
  display: flex;
  justify-content: space-around;
  border: 2px solid #00587A;
  padding: 10px;
  margin: 5px;
}

.card {
  padding: 5px;
  border: 10px;
  margin: 5px;
  margin-top: 10px;

  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #00587A;
  box-shadow:
    inset 0 0 0 6px #00587A,
    inset 0 0 0 8px #ddd;
  line-break: loose;
  min-width: 100px;
  min-height: 100px;
}

.card-play-family {
  padding: 5px;
  border: 10px;
  margin: 5px;
  padding-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  background-image: url("./family.png");
  background-repeat: no-repeat;
  background-size: contain;

  line-break: loose;
  min-width: 200px;
  min-height: 100px;
}

.card-play-nightlife {
  padding: 5px;
  border: 10px;
  margin: 5px;
  padding-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  background-image: url("./nightlife.png");
  background-repeat: no-repeat;
  background-size: contain;

  line-break: loose;
  min-width: 200px;
  min-height: 100px;
}


.card-play-nature {
  padding: 5px;
  border: 10px;
  margin: 5px;
  padding-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  background-image: url("./nature.png");
  background-repeat: no-repeat;
  background-size: contain;

  line-break: loose;
  min-width: 200px;
  min-height: 100px;
}


.card-play-culture {
  padding: 5px;
  border: 10px;
  margin: 5px;
  padding-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  background-image: url("./culture.png");
  background-repeat: no-repeat;
  background-size: contain;

  line-break: loose;
  min-width: 200px;
  min-height: 100px;
}

.card-play-seemore {
  padding: 5px;
  border: 10px;
  margin: 5px;
  padding-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  background-image: url("./seemore.png");
  background-repeat: no-repeat;
  background-size: contain;

  line-break: loose;
  min-width: 200px;
  min-height: 100px;
}

.card-carousel {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  overflow: auto;
  white-space: nowrap;
  overflow-x: auto;

}

.card-icon {
  width: 30px;
  height: 30px;
}


.card-title {
  font-size: 11px;
  text-align: center;
  color: white;
  text-transform: uppercase;
  hyphens: auto;
  white-space: normal;
  width: 80px;
}

.card-carousel::-webkit-scrollbar {
  display: none;
}

.event-image {
  width: 70px;
  height: auto;
}

.play-categories {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  gap: 10px;
  justify-content: center;
}

.play-category-family {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
  background-image: url("./playcat/family-g789bb5581_1920.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow:
    inset 0 0 0 6px #00587A,
    inset 0 0 0 8px #ddd,
    inset 0 0 0 10px #00587A;

  min-width: 150px;
  min-height: 300px;
}

.play-category-nightlife {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
  background-image: url("./playcat/sergio-alves-santos-PeDrafNlY2Y-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow:
    inset 0 0 0 6px #00587A,
    inset 0 0 0 8px #ddd,
    inset 0 0 0 10px #00587A;

  min-width: 150px;
  min-height: 300px;
}

.play-category-nature {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
  background-image: url("./playcat/jeppe-monster-CEJxlDGskZo-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow:
    inset 0 0 0 6px #00587A,
    inset 0 0 0 8px #ddd,
    inset 0 0 0 10px #00587A;

  min-width: 150px;
  min-height: 300px;
}

.play-category-culture {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
  background-image: url("./playcat/julius-yls-NXVMRWGgCfg-unsplash.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  box-shadow:
    inset 0 0 0 6px #00587A,
    inset 0 0 0 8px #ddd,
    inset 0 0 0 10px #00587A;

  min-width: 150px;
  min-height: 300px;
}

.card-play-title {
  color: white;
  text-transform: uppercase;
  hyphens: auto;
  white-space: normal;
  width: 80px;
  font-size: 22px;
  text-decoration: none;
}

.start-button {
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
  border-radius: 0%;
  background-color: #E70013;
  font-size: 22;
  font-weight: bold;
  text-transform: capitalize;
  color: white;
  font-family: VisitDenmark;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.play-image {
  width: 100%;
  height: 200px;

}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.answers {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;


}

.answer-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #00587A;
  min-width: 150px;
  min-height: 100px;
}

.answer-cards-right {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #00587A;
  min-width: 150px;
  min-height: 100px;
}

.sub-card {
  margin: 0px;
  padding: 0;
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: #00587A;
  WIDTH: 100%;
}

.subc-image {
  width: 100%;
  MAX-WIDTH: 200PX;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}


.subcategory-header {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.uwu {
  display: flex;
  flex-direction: column;
  gap: 5px;
  FONT-SIZE: 11PX;
  color: white;
  width: 200px;
}

.owo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.precent{
  font-size: 30px;
  font-weight: bold;
  color: salmon;
}


progress{
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 20px;
}

.location-button{
  padding: 15px;
  background-color: #E70013;
  color: white;
  text-decoration: none;
}

.location-content{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: center;
}